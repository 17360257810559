module.exports = {
    Locales: {
        fi: 'Suomi',
        en: 'English'
    },
    Defaults: {
        Yes: 'Kyllä',
        No: 'Ei',
        Stop: 'Pysähdys',
        Error: 'Virhe',
        Cancel: 'Peruuta',
        Downhill: 'Alamäki',
        Uphill: 'Ylämäki',
        Done: 'Valmis',
        Ok: 'OK',
        On: 'Päällä',
        Off: 'Pois',
        Save: 'Tallenna',
        Edit: 'Muokkaa',
        Remove: 'Poista',
        Open: 'Avaa',
        Date: 'Päivämäärä',
        Close: 'Sulje',
        Hide: 'Piilota',
        Next: 'Seuraava',
        Previous: 'Edellinen',
        GoBack: 'Palaa takaisin',
        ShowMore: 'Näytä lisää',
        CloseThisPage: 'Sulje tämä sivu',
        Confirm: 'Vahvista',
        Reject: 'Hylkää',
        Filter: 'Suodata',
        Name: 'Nimi',
        FirstName: 'Etunimi',
        MiddleName: 'Toinen nimi',
        LastName: 'Sukunimi',
        Keycode: 'Avainkoodi',
        Registered: 'Rekisteröitynyt',
        LastLogin: 'Kirjautunut',
        Email: 'Sähköpostiosoite',
        EmailExample: 'etunimi.sukunimi@osoite.fi',
        Password: 'Salasana',
        Language: 'Kieli',
        Loading: 'Lataa',
        Login: 'Kirjaudu sisään',
        Logout: 'Kirjaudu ulos',
        CreateAccount: 'Luo tili',
        ShowGroup: 'Näytä ryhmä',
        AddRow: 'Lisää rivi',
        RemoveRow: 'Poista rivi',
        SelectLanguage: 'Valitse kieli',
        Altitude: 'Korkeus',
        RouteLength: 'Reitin pituus',
        Difficulty: 'Vaikeustaso',
        Condition: 'Kunto',
        Timestamp: 'Aikaleima',
        DistanceTravelled: 'Kuljettu matka',
        Menu: 'valikko',
        NoRoutes: 'Ei reittejä',
        NoPoints: 'Ei pisteitä',
        Track: 'Reitti',
        Tracks: 'Reitit',
        Points: 'Pistettä',
        Tags: 'Tagit',
        Unauthorized: 'Ei oikeuksia',
        Public: 'Julkinen',
        Private: 'Yksityinen',
        Rename: 'Nimeä uudelleen',
        Refresh: 'Päivitä',
        RefreshPage: 'Lataa sivu uudelleen',
        From: 'Alkaen',
        To: 'Asti',
        Submit: 'Lähetä',
        locationReport: 'Paikan kommentointi',
        messageDelivered: 'Viesti lähetetty onnistuneesti',
        thankYou: 'Kiitos',
        yourComment: 'Sinun kommenttisi',
        typeHere: 'Kirjoita tähän',
        Speed: 'Nopeus',
        HideAll: 'Piilota kaikki',
        ShowAll: 'Näytä kaikki',
        CannotBeEmpty: 'Pakollinen kenttä',
        AreYouHuman: 'Oletko ihminen? (reCaptcha tarkistus epäonnistui)',
        FormSubmitFailed: 'Lähetys epäonnistui. Yritä uudelleen',
        InvalidImage:
            'Virheellinen kuvatiedosto. Sallittu koko on 10MB (png, jpg)',
        UnsupportedBrowser: 'Selainta ei tueta. Kokeile Chrome- tai Firefox selaimella',
        About: 'Minusta',
        Coding: 'Koodaus',
        Projects: 'Projektit',
        Contact: 'Ota yhteyttä',
    },
    Errors: {
      PageNotFound: 'Sivua ei löydy',
      ReturnToHomepage: 'Etusivulle >'
    },
    NatureSounds: {
      title: 'Luontoääniä'
    },
    FrontPage: {
      title: 'Ohjelmistoarkkitehti ja fullstack-kehittäjä Helsingistä 🔥',
      intro: {
          heading: `Moikka! Olen Ville,`,
          heading2: 'kysy minulta mitä vain koodista',
          text: `Asun tällä hetkellä Helsingissä, mutta alunperin kotoisin Oulusta. Olen kiinnostunut IoT -ratkaisuista, mobiilisovelluksista, tekoälystä, ketteristä työtavoista ja tiimin johtamisesta. Tällä hetkellä työskentelen ohjelmistoarkkitehtinä <0>Nitorilla</0>.`
      },
      About: {
          heading: 'Minusta lyhyesti',
          text: `Keskityn modernien ja käyttäjäystävällisten IoT-ratkaisujen ja verkkopalvelujen kehittämiseen. Minulla on kokemusta monenlaisista projekteista, kuten verkkopalveluista, teknisistä auditoinneista, mobiilisovelluksista sekä IoT- ja pilvipohjaisista ratkaisuista. Opin nopeasti uusia ohjelmointikieliä ja olen erittäin motivoitunut laajentamaan teknistä osaamistani, etsin jatkuvasti tapoja kehittyä kehittäjänä. Olen joustava ja yhteistyökykyinen tiimipelaaja, joka tykkää ratkoa monimutkaisia haasteita yhdessä, mutta pärjään myös itsenäisesti.`
      },
      coding: {
          title: 'Parhaat koodauskäytännöt (omasta mielestäni).',
          intro: {
              heading: '1. Aloita pienestä',
              text: `Minun näkemykseni on, että ohjelmistokehityksen tulisi aina edetä joustavasti ja uusien ominaisuuksien tulisi perustua vankkaan asiakkaan tai käyttäjän palautteeseen. Suunnitelmia tulisi voida mukauttaa tarpeen mukaan matkan varrella, ja tämän joustavuuden tulisi olla keskeinen osa kehitysprosessia. Käytännössä tämä tarkoittaa, että ohjelmiston arkkitehtuurin tulisi olla modulaarinen, mikä mahdollistaa uusien ominaisuuksien lisäämisen ja muokkaamisen sujuvasti.`
          },
          intro2: {
              heading: `2. Oikeiden palasten summa`,
              text: `Kaikki ohjelmiston osaset ja riippuvuudet tulee suunnitella niin että niitä voi vaihtaa ns. lennosta. Tämä on erityisen tärkeää jos kyseessä on laajempi projektikokonaisuus. Ohjelmistoarkkitehtuurin kannalta tämä tarkoittaa koko järjestelmän suunnittelua perustuen mikropalveluhin ja focusta tilanhallintaan.`,
              link: 'https://microservices.io/patterns/microservices.html'
          },
          intro3: {
              heading: '3. Jatkuva oppiminen',
              quote1: 'Kuten Isaac Asimov on sanonut,',
              quote2: `“The day you stop learning is the day you begin decaying.”`,
              text: `Etsin jatkuvasti uusia tapoja parantaa osaamistani joko hyödyntämällä aiempaa tietämystä tai tutkimalla täysin uusia, mielestäni hyödyllisiä aloja. Tällä hetkellä olen todella kiinnostunut oppimaan Flutteria ja Denoa. Vapaa-ajalla harjoittelen palstaviljelyä, harrastan sählyä ja pyöräilen.`
          }
      },
      projects: {
          title: 'Viimeisimmät projektit',
          subtitle: `Tässä muutama vapaa-ajan esimerkkiprojekti:`,
          jsnake: {
            alt: 'JSnake',
            link: '/jsnake',
            linkText: 'Linkki projektisivulle',
            title: 'JSnake projekti',
            text: `Tämä on yksinkertainen Snake-peli, joka on toteutettu Vanilla JavaScriptillä. Peli on responsiivinen ja toimii hyvin myös mobiililaitteilla. Pelin ulkoasu on inspiroitu Nokia 3310 -puhelimen pelistä.`,
            keywords:
                'Vanilla JS, Snake game, Nokia 3310, HTML5 canvas, Responsive'
          },
          naturesounds: {
            alt: 'Luontoääniä',
            link: '/nature',
            linkText: 'Linkki projektisivulle',
            title: 'Nature sounds -projekti',
            text: `Törmäsin "äänikupla" konseptiin kuunnellessani yhtä suosikki musiikkipodcasteistäni nimeltä "Twenty Thousand Hertz" ja halusin tehdä oman luontoteemaisen "äänikuplan" käyttäen podcastissa mainittuja Environments albumeita.

            Environments on sarja LP-levyjä, kasetteja, 8-raitaisia kasetteja ja CD-levyjä, jotka tuottaja ja äänittäjä Irv Teibel (1938–2010) on luonut Syntonic Research Inc.:lle vuosina 1969–1979. Sarja koostuu luonnonäänien tallennuksista, kuten merenranta pauhuvilla aalloilla tai ukkosmyrsky, ilman musiikillista säestystä. (https://en.wikipedia.org/wiki/Environments_(album_series))`,
            keywords:
                'Nature Sounds, Environments, Sound bubble, Concentration, Environments, Irv Teibel'
        },
          arduinomoisture: {
              title: 'Arduino kasvienkastelu projekti',
              text: `Rakensin kasvieni maaperän kosteustason seurantaan laitteen käyttäen Arduino MKR WiFi 1010 kehitysalustaa. Laite käyttää kapasitiivista kosteusanturia ja kertoo kosteustason helposti luettavana prosenttilukuna pienellä OLED-näytöllä.

              Laitetta voidaan käyttää myös hälytyksiin, eli jos kosteus laskee liian alhaiseksi, laite lähettää viestin automaattisesti telegram botin kautta. Laitteen koteloon käytin Ultimaker3 3D -tulostinta lähikirjastossa.`,
              keywords:
                  'Arduino, MKR 1010, Wifi, Kosteusmittari, 3D tulostus, Ultimaker3, telegram botti'
          },
          terrainsenseproject: {
              alt: 'Terrainsense',
              title: 'Terrainsense project',
              text: `Metsäpolun tai tien epätasaisuuksien mittaaminen pyörään kiinnitetyllä kiihtyvyysanturilla. Anturi on Suunnon Movesense -anturi ja se oli yhdistetty Android -sovellukseen. Projekti alkoi vuonna 2018 Junction hackathon -kilpailuna ja jatkui sitten kolmeen eri pilottihankkeeseen.

              Palvelun keskeiset ominaisuudet olivat:
              - Bluetooth sensorin kiihtyvyys data
              - Reittien muokkaus ja hallinnointi web-käyttöliittymältä
              - GPS paikannus
              - Videon tallennus ja toisto hyödyntäen AWS S3 bucketteja
              - Koneoppimismalli (AWS Recognition) videoiden analysoimiseksi (kuoppien löytäminen).

              Palvelu suljettiin tammikuussa 2023`,
              videoButton: 'Demo video',
              keywords:
                  'React, Android, AWS EC2, S3, Recognition, Movesense sensori, Mittaus, GPS-palvelut'
          },
          datawarehouse: {
              alt: 'Datawarehouse',
              title: 'Datawarehouse projekti',
              text: `Projekti koostui tietojen keräämisestä laitteen käytöstä, testausraporteista, analytiikasta ja niihin liittyvistä taloudellisista keskeisistä kiinnostuksen kohteista (KPI) "saman katon alla" Power BI: n avulla.`,
              keywords: 'Python, Power BI'
          },
          profilesite: {
              alt: 'Tämä verkkosivusto (Perustettu 2016 ja viimeisin UI päivitys 2021)',
              title: `Tämä sivusto ja sen Github Actions- pohjainen CI/CD -putki`,
              text: `Tein tämän siistin pienen verkkosivuston esitelläkseni itseäni, ajatuksiani ja tarjoamaan helpon tavan ottaa yhteyttä. Sivusto on rakennettu Reactilla ja se on julkaistu AWS S3: ssa. CI/CD -putki on rakennettu Github Actionsin avulla.`,
              keywords: 'React, Github Actions, AWS S3, Cloudfront'
          },
          codenomicon: {
              title: 'Codenomicom projekti (2015)',
              text: `Tämän projektin tavoitteena oli parantaa olemassa olevien työkalujen käyttökokemusta, jotta käyttäjät voivat hakea tapahtumatietokantaansa käyttämällä uutta ensisijaista hakualustaa varten kehitettyä verkkopohjaista käyttöliittymää myös silloin, kun kyseisellä asiakkaalla ei ole yleinen haun taustaohjelma on asennettu. Tämän hakujärjestelmän oli vastattava ensisijaisen järjestelmän suorituskykyä hakulausekkeiden alaluokassa.`,
              keywords: 'Datatools, Python, Back-end, Flask, SQL, Linux'
          },
          endingwords: `...ja monia muita.
Kerron mielelläni lisää 🙂
Jos haluat ottaa yhteyttä, voit tehdä sen joko sähköpostilla tai linkkarissa`,
      }
  }
}

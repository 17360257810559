import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Typography, makeStyles } from '@material-ui/core'
import { LanguageChanger } from '../../components/UI/LangueChanger/languageChanger'
import ArrowLeft from '@material-ui/icons/ArrowLeft'

const useStyles = makeStyles(theme => ({
    wrapper: {
        width: '100%',
        padding: '2rem',
        [theme.breakpoints.down('sm')]: {
            marginTop: '3rem',
            padding: '1rem'
        }
    },
    languageChangerContainer: {
        top: '1rem',
        position: 'absolute',
        right: '1rem'
    },
    backArrow: {
        display: 'inline',
        position: 'fixed',
        right: '5',
        backgroundColor: 'white',
        borderRadius: '5px',
        border: '1px solid black',
        backgroundRepeat: 'no-repeat',
        overflow: 'hidden',
        outline: 'none',
        top: '1rem',
        fontSize: '2rem',
        zIndex: '1',
        cursor: 'pointer',
        color: '#3695ed'
    },
    playerContainer: {
        marginRight: 'auto',
        marginLeft: 'auto',
        maxWidth: '48rem',
        [theme.breakpoints.down('lg')]: {
            maxWidth: '48rem'
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '32rem'
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '18rem'
        }
    },
    heading: {
        fontSize: '2.25rem',
        marginBottom: '1rem',
        fontWeight: 600,
        [theme.breakpoints.down('xl')]: {
            fontSize: '2.25rem !important'
        },
        [theme.breakpoints.down('lg')]: {
            paddingBottom: '0px',
            fontSize: '2rem !important'
        },
        [theme.breakpoints.down('md')]: {
            paddingBottom: '0px',
            fontSize: '1.5rem !important'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.2rem !important',
            paddingBottom: '20px'
        }
    },
    player: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
            maxHeight: '20rem'
        },
        [theme.breakpoints.down('xs')]: {
            maxHeight: '10rem'
        }
    }
}))

export default function NatureSoundsPage() {
    const classes = useStyles()
    const { t } = useTranslation()

    const backToFrontPage = () => {
        document.location.href = '/'
    }

    return (
        <Grid className={classes.wrapper} data-cy="naturesounds-page">
            <ArrowLeft
                onClick={backToFrontPage}
                className={classes.backArrow}
            />
            <div className={classes.languageChangerContainer}>
                <LanguageChanger whiteBg />
            </div>
            <div className={classes.playerContainer}>
                <Typography variant="h1" className={classes.heading}>
                    {t('NatureSounds.title')}
                </Typography>
                <iframe
                    className={classes.player}
                    width="620"
                    height="480"
                    src="https://www.youtube.com/embed/playlist?list=PLe7RBDUpbnkNbCBjHMBxotqYOdBJwnwZe&index=2&autoplay=1"
                    title="Natude sounds"
                    frameBorder="0"
                    allow="autoplay"
                    allowFullScreen
                ></iframe>
            </div>
        </Grid>
    )
}

module.exports = {
    Locales: {
        fi: 'Suomi',
        en: 'English'
    },
    Defaults: {
        Yes: 'Yes',
        No: 'No',
        Stop: 'Stop',
        Error: 'Error',
        Cancel: 'Cancel',
        Downhill: 'Downhill',
        Uphill: 'Uphill',
        Done: 'Done',
        Ok: 'OK',
        On: 'ON',
        Off: 'OFF',
        Save: 'Save',
        Edit: 'Edit',
        Remove: 'Remove',
        Open: 'Open',
        Close: 'Close',
        Date: 'Date',
        Hide: 'Hide',
        Next: 'Next',
        Previous: 'Previous',
        GoBack: 'Go back',
        ShowMore: 'Show more',
        CloseThisPage: 'Close this page',
        Confirm: 'Confirm',
        Reject: 'Reject',
        Filter: 'Filter',
        Name: 'Name',
        FirstName: 'First name',
        MiddleName: 'Middle name',
        LastName: 'Last name',
        Keycode: 'Keycode',
        Registered: 'Registered',
        LastLogin: 'Last login',
        Email: 'Email',
        EmailExample: 'fistname.lastname@address.com',
        Password: 'Password',
        Language: 'Language',
        Loading: 'Loading',
        Login: 'Login',
        Logout: 'Logout',
        CreateAccount: 'Create an account',
        AddRow: 'Add row',
        RemoveRow: 'Remove row',
        SelectLanguage: 'Select language',
        Altitude: 'Altitude',
        RouteLength: 'Route length',
        Difficulty: 'Difficulty',
        Condition: 'Condition',
        Timestamp: 'Timestamp',
        DistanceTravelled: 'Distance travelled',
        Menu: 'menu',
        NoRoutes: 'No routes found',
        NoPoints: 'No points',
        Track: 'Track',
        Tracks: 'Tracks',
        Points: 'Points',
        Tags: 'Tags',
        Unauthorized: 'Unauthorized',
        Public: 'Public',
        Private: 'Private',
        Rename: 'Rename',
        Refresh: 'Refresh',
        RefreshPage: 'Refresh page',
        From: 'From',
        To: 'To',
        Submit: 'Submit',
        locationReport: 'Location feedback',
        messageDelivered: 'Your message was submitted',
        thankYou: 'Thank you',
        yourComment: 'Your feedback comment',
        typeHere: 'Type here',
        Speed: 'Speed',
        HideAll: 'Hide All',
        ShowAll: 'Show All',
        CannotBeEmpty: 'Cannot be empty field',
        AreYouHuman: 'Are you human? (reCaptcha failed)',
        FormSubmitFailed: 'Problem during form submission. Try again',
        InvalidImage: 'Invalid image file. 5MB max size (png, jpg)',
        UnsupportedBrowser:
            'Browser is unsupported. Try with Chrome- or Firefox browser',
        About: 'About',
        Coding: 'Coding',
        Projects: 'Projects',
        Contact: 'Contact'
    },
    Errors: {
        PageNotFound: 'Page not found',
        ReturnToHomepage: 'Go to frontpage >'
    },
    NatureSounds: {
        title: 'Nature sounds'
    },
    FrontPage: {
        title: 'SW Architect and full stack developer from Helsinki 🔥',
        intro: {
            heading: `Hi! I'm Ville`,
            heading2: 'ask me anything about coding',
            text: `Currently based in Helsinki, Finland, but born and raised in Oulu. I am interested in IoT solutions, mobile apps, artificial intellegence, agile work and team leading. Right now I'm working as a software architect at <0>Nitor</0>.`
        },
        About: {
            heading: 'About me',
            text: `My primary focus is on developing modern, user-friendly IoT solutions and web services. I have experience working on a variety of projects, including web services, technical audits, mobile applications, IoT, and Cloud-based solutions. I am quick to learn new programming languages and highly motivated to expand my technical expertise, continuously seeking opportunities for growth as a developer. I am an adaptable and collaborative team player, comfortable tackling complex challenges in a group setting, while also being capable of working independently with minimal guidance.`
        },
        coding: {
            title: 'Best coding practices (IMHO)',
            intro: {
                heading: '1. PoC all the things!!!',
                text: `In my opinion, software development should always proceed flexibly, and new features should be based on solid customer or user feedback. Plans should be adaptable as needed along the way, and this flexibility should be a central part of the development process. In practice, this means that the software architecture should be modular, allowing for the smooth addition and modification of new features.`
            },
            intro2: {
                heading: `2. "Lego it up"`,
                text: `All components and their dependencies should be designed in a "hot-swappable" way. This is especially true if the project is more complex. From software architectural point of view this would mean designing the whole system based on microservices and focus on handling state correctly.`,
                link: 'https://microservices.io/patterns/microservices.html'
            },
            intro3: {
                heading: '3. Never Stop Learning',
                quote1: `As Isaac Asimov has said,`,
                quote2: ` “The day you stop learning is the day you begin decaying.”`,
                text: `Continuous learning -
                I am constantly seeking new ways to improve my skills, either by leveraging my existing knowledge or exploring entirely new, personally beneficial areas. Currently, I am very interested in learning Flutter and Deno. In my free time, I practice communal gardening, play floorball, and go cycling.`
            }
        },
        projects: {
            title: 'Latest projects',
            subtitle: `Here are some example projects I've worked on my free time:`,
            jsnake: {
                alt: 'JSnake',
                link: '/jsnake',
                linkText: 'Link to project',
                title: 'JSnake project',
                text: `Vanilla JS project to create a snake game. The game is based on the classic snake game from the Nokia 3310. The game is created using HTML5 canvas and vanilla JavaScript. The game is responsive and can be played on both desktop and mobile devices.`,
                keywords:
                    'Vanilla JS, Snake game, Nokia 3310, HTML5 canvas, Responsive'
            },
            naturesounds: {
                alt: 'Nature Sounds',
                link: '/nature',
                linkText: 'Link to project',
                title: 'Nature sounds project',
                text: `I stumbled upon concept of 'sound bubbles' by listening one of my favorite music podcast "Twenty Thousand Hertz" and wanted to create my own nature themed sound bubble based on Environments albums mentioned in the podcast.

                Environments is a series of LPs, cassettes, 8-Track cartridges and CDs created by producer and sound recordist Irv Teibel (1938–2010) for Syntonic Research Inc. between 1969 and 1979. The series consists of recordings of natural sounds such as a seashore with crashing waves or a thunderstorm with falling rain, without musical accompaniment. (https://en.wikipedia.org/wiki/Environments_(album_series))`,
                keywords:
                    'Nature Sounds, Environments, Sound bubble, Concentration, Environments, Irv Teibel'
            },
            arduinomoisture: {
                title: 'Arduino plant watering project',
                text: `I used Arduino MKR WiFi 1010 developing board to monitor the soil moisture level of my plants. The device uses capacitive moisture sensor and tells the moisture level as easy-to-read percentage on a tiny OLED screen.

                It can also be used to set up alarms if the moisture level gets too low and send out telegram message automatically via a telegram bot. For the case I used a Ultimaker3 3D printer in my local library.`,
                keywords:
                    'Arduino, MKR1010, Wifi, Moisture sensor, 3D printing, Ultimaker3, telegram bot'
            },
            terrainsenseproject: {
                alt: 'Terrainsense',
                title: 'Terrainsense project',
                text: `Service to measure rail roughness or road condition using a bike-mounted
        acceleration sensor. Sensor used was Suunto's Movesense sensor and it was paired with android app. The project started in 2018 as a Junction hackathon entry and then continued on to three different pilot projects.

        Highlight features included
        - Bluetooth sensor acceleration data
        - Editing and moderating recorded tracks via web GUI
        - GPS tracking
        - Video recording and playback utilizing AWS S3 buckets
        - Machine Learning (AWS Recognition) to analyze video frames for pitholes and such

        The service got discontinued in January 2023`,
                videoButton: 'Demo video',
                keywords:
                    'React, Android, AWS S3, EC2, Recognition, Movesense sensor, Tracking, GPS-services'
            },
            datawarehouse: {
                alt: 'Datawarehouse',
                title: 'Datawarehouse project',
                text: `Project consisted of collecting data about device usage, testing reports, analytics and related finacial key points of interests (KPI's) "under the same roof" using Power BI.`,
                keywords: 'Python, Power BI'
            },
            profilesite: {
                alt: 'This profile site (Since 2016 with latests UI refresh 2021)',
                title: `This website and its' CI/CD pipeline using Github Actions`,
                text: `To showcase my talent I made this neat little website so you can slide into my DM if you're interested.
        The CI/CD pipeline was made using Github Actions and it's published to a AWS S3 bucket from which it's served via Cloudfront.`,
                keywords: 'React, Github Actions, AWS S3, Cloudfront'
            },
            codenomicon: {
                title: 'Codenomicom project (2015)',
                text: `The goal of this project was to improve the user experience of the existing tools, so that the users can search their incident event database using the web based user interface developed for the new primary search platform, also when the particular customer doesn’t have our generic search backend installed. This secondary search system had to match the performance of the primary system in a sub-category of search queries.`,
                keywords: 'Datatools, Python, Back-end, Flask, SQL, Linux'
            },
            endingwords: `...and many more
I'm happy to elaborate 🙂
If you have questions, please email me or contact me in LinkedIn`
        }
    }
}

import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next'
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowLeft from '@material-ui/icons/ArrowLeft'
import { LanguageChanger } from '../../components/UI/LangueChanger/languageChanger'
import SnakeImg from '../../assets/images/snake.jpg'

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    padding: '2rem',
    [theme.breakpoints.down('sm')]: {
        marginTop: '3rem',
        padding: '1rem'
    }
  },
  languageChangerContainer: {
      top: '1rem',
      position: 'absolute',
      right: '1rem'
  },
  button: {
    borderRadius: '5px',
    border: '1px solid black',
    backgroundRepeat: 'no-repeat',
    padding: '4px',
    overflow: 'hidden',
    outline: 'none',
    top: '1rem',
    fontSize: '2rem',
    zIndex: '1',
    cursor: 'pointer',
    backgroundColor: "rgba(13, 223, 62, 0.7)",
    color: "white",
  },
  backArrow: {
    display: 'inline',
    position: 'fixed',
    right: '5',
    backgroundColor: 'white',
    color: '#3695ed'
  },
  playerContainer: {
      marginRight: 'auto',
      marginLeft: 'auto',
      maxWidth: '48rem',
      [theme.breakpoints.down('lg')]: {
          maxWidth: '48rem'
      },
      [theme.breakpoints.down('md')]: {
          maxWidth: '32rem'
      },
      [theme.breakpoints.down('xs')]: {
          maxWidth: '18rem'
      }
  },
  splashScreen: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#000",
    color: "#0f0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    zIndex: 20,
  },
  splashImg: {
    paddingLeft: '15px',
    width: '500px',
    margin: '0 auto',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
        padding: '1rem 0 0 0',
        width: '100%'
    }
  },
  canvas: {
    background: "#000",
    display: "block",
    margin: "20px auto",
    border: "2px solid #333",
    width: "90%",
    maxWidth: 400,
    height: "auto",
  },
  gameOverOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0,0,0,0.7)",
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    color: "#fff",
    zIndex: 10,
  },
  highScores: {
    marginTop: 20,
    maxWidth: 300,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "left",
  },
  endGame: {
    backgroundColor: "rgba(141, 33, 33, 0.7)",
  }
}));

const JSnakePage = () => {
  const classes = useStyles();
  const { t } = useTranslation()

  useEffect(() => {
    let canvas = document.getElementById("gameCanvas");
    let ctx = canvas.getContext("2d");
    let scoreDisplay = document.getElementById("scoreDisplay");
    let highScoresList = document.getElementById("highScoresList");
    let splashScreen = document.getElementById("splashScreen");
    let startButton = document.getElementById("startBtn");
    let gameOverOverlay = document.getElementById("gameOverOverlay");
    let finalScoreText = document.getElementById("finalScore");
    let playerNameInput = document.getElementById("playerName");
    let submitScoreButton = document.getElementById("submitScore");
    let endGameButton = document.getElementById("endGame");

    const GRID_SIZE = 20;
    let tileCount = 20;
    const SWIPE_THRESHOLD = 30;
    let touchStartX = 0;
    let touchStartY = 0;
    const GAME_SPEED = 200;
    let gameInterval;
    let score;
    let snakeDirection = { x: 1, y: 0 };
    let apple;
    let snake;

    function resizeCanvas() {
      const desiredWidth = Math.floor(window.innerWidth * 0.9);
      const canvasWidth = Math.min(desiredWidth, 400);
      const adjustedWidth = Math.floor(canvasWidth / GRID_SIZE) * GRID_SIZE;
      canvas.width = adjustedWidth;
      canvas.height = adjustedWidth;
      tileCount = canvas.width / GRID_SIZE;
    }

    function initializeGame() {
      snake = [
        { x: Math.floor(tileCount / 2), y: Math.floor(tileCount / 2) },
        { x: Math.floor(tileCount / 2) - 1, y: Math.floor(tileCount / 2) },
        { x: Math.floor(tileCount / 2) - 2, y: Math.floor(tileCount / 2) },
      ];
      snakeDirection = { x: 1, y: 0 };
      score = 0;
      scoreDisplay.textContent = "Score: " + score;
      spawnApple();
      if (gameInterval) clearInterval(gameInterval);
      gameInterval = setInterval(gameLoop, GAME_SPEED);
    }

    function gameLoop() {
      updateGame();
      drawGame();
    }

    function updateGame() {
      const newHead = {
        x: snake[0].x + snakeDirection.x,
        y: snake[0].y + snakeDirection.y,
      };

      if (
        newHead.x < 0 ||
        newHead.x >= tileCount ||
        newHead.y < 0 ||
        newHead.y >= tileCount ||
        snake.some(segment => segment.x === newHead.x && segment.y === newHead.y)
      ) {
        endGame();
        return;
      }

      snake.unshift(newHead);
      if (newHead.x === apple.x && newHead.y === apple.y) {
        score++;
        scoreDisplay.textContent = "Score: " + score;
        spawnApple();
      } else {
        snake.pop();
      }
    }

    function drawGame() {
      ctx.fillStyle = "#000";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = "red";
      ctx.fillRect(apple.x * GRID_SIZE, apple.y * GRID_SIZE, GRID_SIZE, GRID_SIZE);
      ctx.fillStyle = "lime";
      snake.forEach(segment => {
        ctx.fillRect(
          segment.x * GRID_SIZE,
          segment.y * GRID_SIZE,
          GRID_SIZE - 1,
          GRID_SIZE - 1
        );
      });
    }

    function spawnApple() {
      apple = {
        x: Math.floor(Math.random() * tileCount),
        y: Math.floor(Math.random() * tileCount),
      };
      if (snake.some(segment => segment.x === apple.x && segment.y === apple.y)) {
        spawnApple();
      }
    }

    function endGame() {
      clearInterval(gameInterval);
      finalScoreText.textContent = "You got " + score + " points.";
      playerNameInput.value = "";
      if (score === 0) {
        playerNameInput.style.display = "none";
        document.querySelector("#gameOverOverlay p:nth-of-type(2)").style.display = "none";
        submitScoreButton.textContent = "Play Again";
      } else {
        playerNameInput.style.display = "inline-block";
        document.querySelector("#gameOverOverlay p:nth-of-type(2)").style.display = "block";
        submitScoreButton.textContent = "Save score and play again";
      }
      gameOverOverlay.style.display = "flex";
    }

    function saveHighScore(playerName, scoreValue) {
      let highScores = JSON.parse(localStorage.getItem("snakeHS")) || [];
      highScores.push({ n: playerName, s: scoreValue });
      highScores.sort((a, b) => b.s - a.s);
      if (highScores.length > 10) highScores.splice(10);
      localStorage.setItem("snakeHS", JSON.stringify(highScores));
    }

    function updateHighScoresDisplay() {
      let highScores = JSON.parse(localStorage.getItem("snakeHS")) || [];
      highScoresList.innerHTML = "";
      highScores.forEach(entry => {
        const li = document.createElement("li");
        li.textContent = entry.n + " - " + entry.s;
        highScoresList.appendChild(li);
      });
    }

    document.addEventListener("keydown", (e) => {
      switch (e.code) {
        case "ArrowUp":
          if (snakeDirection.y !== 1) snakeDirection = { x: 0, y: -1 };
          break;
        case "ArrowDown":
          if (snakeDirection.y !== -1) snakeDirection = { x: 0, y: 1 };
          break;
        case "ArrowLeft":
          if (snakeDirection.x !== 1) snakeDirection = { x: -1, y: 0 };
          break;
        case "ArrowRight":
          if (snakeDirection.x !== -1) snakeDirection = { x: 1, y: 0 };
          break;
        default:
          break;
      }
    });

    canvas.addEventListener("touchstart", (e) => {
      const touch = e.touches[0];
      touchStartX = touch.clientX;
      touchStartY = touch.clientY;
    });

    canvas.addEventListener("touchend", (e) => {
      const touch = e.changedTouches[0];
      const deltaX = touch.clientX - touchStartX;
      const deltaY = touch.clientY - touchStartY;
      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        if (deltaX > SWIPE_THRESHOLD && snakeDirection.x !== -1)
          snakeDirection = { x: 1, y: 0 };
        else if (deltaX < -SWIPE_THRESHOLD && snakeDirection.x !== 1)
          snakeDirection = { x: -1, y: 0 };
      } else {
        if (deltaY > SWIPE_THRESHOLD && snakeDirection.y !== -1)
          snakeDirection = { x: 0, y: 1 };
        else if (deltaY < -SWIPE_THRESHOLD && snakeDirection.y !== 1)
          snakeDirection = { x: 0, y: -1 };
      }
    });

    submitScoreButton.addEventListener("click", () => {
      if (score === 0) {
        gameOverOverlay.style.display = "none";
        initializeGame();
        return;
      }
      const playerName = playerNameInput.value.trim();
      if (playerName === "") {
        alert("Enter your name!");
        return;
      }
      saveHighScore(playerName, score);
      updateHighScoresDisplay();
      gameOverOverlay.style.display = "none";
      initializeGame();
    });

    endGameButton.addEventListener("click", () => {
      backToFrontPage()
    });

    window.addEventListener("resize", () => {
      resizeCanvas();
      drawGame();
    });

    updateHighScoresDisplay();
    resizeCanvas();

    startButton.addEventListener("click", () => {
      splashScreen.style.display = "none";
      resizeCanvas();
      initializeGame();
    });
  }, []);


  const backToFrontPage = () => {
    document.location.href = '/'
  }

  return (
    <Grid className={classes.wrapper} data-cy="jsnake-page">
        <ArrowLeft
            onClick={backToFrontPage}
            className={`${classes.button} ${classes.backArrow}`}
        />
        <div className={classes.languageChangerContainer}>
            <LanguageChanger whiteBg />
        </div>
        <div className={classes.playerContainer}>
          <div id="splashScreen" className={classes.splashScreen}>
            <pre className={classes.pre}>
            <img
                src={SnakeImg}
                className={classes.splashImg}
                title={t(
                    'FrontPage.projects.jsnake.title'
                )}
            />
            </pre>
            <button id="startBtn" className={classes.button}>Start Game</button>
          </div>
          <h1>JSnake</h1>
          <canvas id="gameCanvas" className={classes.canvas}></canvas>
          <div id="scoreDisplay">Score: 0</div>
          <div id="highScores" className={classes.highScores}>
            <h2>High Scores</h2>
            <ol id="highScoresList"></ol>
          </div>
          <div id="gameOverOverlay" className={classes.gameOverOverlay}>
            <h2>Game Over!</h2>
            <p id="finalScore"></p>
            <p>Enter your name for the score:</p>
            <input type="text" id="playerName" placeholder="Name" />
            <br /><br />
            <button id="submitScore" className={classes.button}>Save score and play again</button>
            <br /><br />
            <button id="endGame" className={`${classes.button} ${classes.endGame}`}>End game</button>
          </div>
        </div>
    </Grid>
  );
};

export default JSnakePage;
